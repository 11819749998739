<template>
  <div>
    <DxPopupModal
      id="createOrUpdateInhabitant"
      :visible="showPopup"
      v-model="showPopup"
      :drag-enabled="true"
      :show-title="true"
      :width="800"
      :title="title"
      height="auto"
      @hidden="cancel"
    >
      <DxPopupPosition at="center" my="center" />
      <div class="alert" :class="[classes]" v-if="message">
        {{ message }}
      </div>

      <form @submit="save">
        <DxForm :form-data="formData" ref="form">
          <DxSimpleItem data-field="id" :visible="false" />
          <DxSimpleItem data-field="debtorId" :visible="false" />

          <DxSimpleItem
            data-field="name"
            editor-type="dxTextArea"
            :is-required="true"
            :editor-options="{ showClearButton: true, inputAttr: { autocomplete: 'new-user-inhabitant-name' } }"
          >
            <DxLabel text="ФИО" />
          </DxSimpleItem>
          <DxGroupItem :col-count="2">
            <DxSimpleItem
              data-field="isMale"
              editor-type="dxRadioGroup"
              :editor-options="{
                items: isMaleArray,
                layout: 'horizontal',
                valueExpr: 'id',
                displayExpr: 'name',
              }"
            >
              <DxLabel text="Пол" />
            </DxSimpleItem>

            <DxGroupItem :col-count="2">
              <DxSimpleItem data-field="isDied" editor-type="dxCheckBox">
                <DxLabel text="Умер" />
              </DxSimpleItem>
              <DxSimpleItem data-field="deathDate" editor-type="dxDateBox" width="100px" :editor-options="{ useMaskBehavior: true }">
                <DxLabel :visible="false" />
              </DxSimpleItem>
            </DxGroupItem>

            <DxSimpleItem
              data-field="birthDate"
              editor-type="dxDateBox"
              width="100px"
              :editor-options="{ showClearButton: true, useMaskBehavior: true, placeholder: 'введите или выберите' }"
            >
              <DxLabel text="Дата рождения" />
              <DxRequiredRule />
            </DxSimpleItem>
            <DxSimpleItem data-field="birthPlace">
              <DxLabel text="Место рождения" />
            </DxSimpleItem>

            <DxSimpleItem data-field="snils" :editor-options="{ mask: 'CCC-CCC-CCC CC', useMaskedValue: true }">
              <DxLabel text="СНИЛС" />
            </DxSimpleItem>
            <DxSimpleItem data-field="inn">
              <DxLabel text="ИНН" />
            </DxSimpleItem>

            <DxSimpleItem
              data-field="registrationAddress"
              caption="Адрес регистрации"
              edit-cell-template="btnSetAddress"
              :col-span="2"
              template="btnSetAddress"
            >
              <DxLabel location="top" text="Адрес регистрации" />
            </DxSimpleItem>

            <DxSimpleItem
              data-field="passportSerialNumber"
              :editor-options="{
                mask: 'CCCC CCCCCC',
                maskChar: '‒',
                useMaskedValue: true,
              }"
            >
              <DxLabel text="Номер паспорта" />
            </DxSimpleItem>
            <DxSimpleItem
              data-field="passportIssueDate"
              editor-type="dxDateBox"
              :editor-options="{ showClearButton: true, useMaskBehavior: true, placeholder: 'введите или выберите' }"
            >
              <DxLabel text="Дата выдачи" />
            </DxSimpleItem>
            <DxSimpleItem data-field="passportIssuedBy" editor-type="dxTextArea">
              <DxLabel text="Кто выдал" />
            </DxSimpleItem>
            <DxSimpleItem
              data-field="passportDivisionCode"
              :editor-options="{
                mask: 'CCC-CCC',
                useMaskedValue: true,
                showClearButton: true,
              }"
            >
              <DxLabel text="Код подразд." />
            </DxSimpleItem>
            <DxSimpleItem data-field="email" :editor-options="{ showClearButton: true }">
              <DxLabel text="Email" />
            </DxSimpleItem>
            <DxSimpleItem
              data-field="phone"
              :editor-options="{
                mask: '(000) 000-00-00',
                useMaskedValue: false,
                showClearButton: true,
              }"
            >
              <DxLabel text="Телефон" />
            </DxSimpleItem>
          </DxGroupItem>

          <DxGroupItem :col-count="2">
            <DxButtonItem horizontal-alignment="left">
              <DxButtonOptions text="Отменить" type="danger" :on-click="cancel" />
            </DxButtonItem>
            <DxButtonItem horizontal-alignment="right">
              <DxButtonOptions text="Сохранить" type="success" :useSubmitBehavior="true" />
            </DxButtonItem>
          </DxGroupItem>

          <template #btnSetAddress="{}">
            <div class="d-flex">
              <DxTextBox width="100%" ref="tbRegistrationAddress" v-model="formData.registrationAddress" />
              <DxButton text="Адрес совпадает с адресом ЛС" class="ms-2" @click="setAddress()" />
            </div>
          </template>
        </DxForm>
      </form>
    </DxPopupModal>
  </div>
</template>

<script>
const loadUrl = `${process.env.VUE_APP_URL}/api/inhabitants/inhabitant-by-id/`;
const createOrUpdateUrl = `${process.env.VUE_APP_URL}/api/inhabitants/create-or-update`;
import axios from "axios";
import { blink } from "@/helpers";
const isMaleArray = [
  { id: true, name: "муж." },
  { id: false, name: "жен." },
];

import { DxForm, DxButtonItem, DxButtonOptions, DxSimpleItem, DxGroupItem, DxLabel, DxRequiredRule } from "devextreme-vue/form";

export default {
  components: {
    DxForm,
    DxButtonItem,
    DxButtonOptions,
    DxSimpleItem,
    DxGroupItem,
    DxLabel,
    DxRequiredRule,
  },
  props: {
    id: {
      type: Number,
    },
    debtorId: {
      required: true,
    },
    debtorData: {
      type: Object,
    },
  },
  name: "CreateOrUpdateInhabitantComponent",
  emits: ["on-inhabitant-saved", "on-inhabitant-cancel"],
  watch: {
    id() {
      this.showPopup = this.id != null;
      if (this.id != null && this.id != 0) this.loadInhabitant();
    },
  },
  computed: {
    classes() {
      return [this.isError ? "alert-danger" : "alert-success"];
    },
    title() {
      return this.id == 0 ? "Создание жителя" : "Редактирование жителя";
    },
  },
  mounted() {
    this.formData.debtorId = this.debtorId;
  },
  data() {
    return {
      isMaleArray,
      currentCity: process.env.VUE_APP_CITY,
      loadUrl,
      createOrUpdateUrl,
      showPopup: false,
      message: null,
      isError: false,
      formData: {
        id: 0,
        debtorId: 0,
        birthDate: null,
        birthPlace: null,
        name: null,
        isMale: true,
        inn: null,
        snils: null,
        registrationAddress: null,
        passportSerialNumber: null,
        passportIssueDate: null,
        passportIssuedBy: null,
        passportDivisionCode: null,
        email: null,
        phone: null,
        isDied: false,
        deathDate: null,
      },
    };
  },
  methods: {
    setAddress() {
      this.formData.registrationAddress =
        this.currentCity + ", " + this.debtorData.house.address + " " + "кв. " + this.debtorData.debtor.flatNumber;
      blink(this, 3, "tbRegistrationAddress");
    },

    async save(e) {
      e.preventDefault();

      let data = this.formData;
      try {
        let response = await axios.post(this.createOrUpdateUrl, data);
        this.$emit("on-inhabitant-saved", response.data);
        this.resetForm();
      } catch (error) {
        this.message = error;
        this.isError = true;
      }
    },
    cancel() {
      this.$emit("on-inhabitant-cancel");
      this.$refs.form.instance.resetValues();
      this.formData.registrationAddress = null;
    },
    async loadInhabitant() {
      try {
        var response = await axios.get(this.loadUrl + this.id);
        var data = response.data;
        this.formData = data;
      } catch (error) {
        console.log(error.response);
      }
    },

    resetForm() {
      this.$refs.form.instance.resetValues();
    },
  },
};
</script>
